const store = {
	state: {
		currentView: 0,
		showNav: false,
	},
	mutations: {
		setCurrentView(state, data) {
			state.currentView = data
		},
		
		toggleNav(state, data) {
			state.showNav = data
		}
	}
}

module.exports = store