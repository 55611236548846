<template>
	<nav :class="{show: showNav}">
		<ul>
			<li>
				<h1>VoIP</h1>
			</li>
			<li v-for="(route, index) in routes" v-bind:key="index">
				<router-link :class="{active: current == index}" :to="route.path" @click.native="currentView(index)">{{ route.text }}</router-link>
			</li>

			<li class="logout">
				<hr>
				<a href="#" @click.prevent="logout">Salir</a>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	data() {
		return {
			routes: [
				{ path: '/destinos', text: 'Llamadas por destino' },
				{ path: '/duracion', text: 'Duración por compañía' },
				{ path: '/rangos', text: 'Duración por rango' },
				{ path: '/codigos', text: 'Códigos por llamada' }
			]
		}
	},
	computed: {
		current() {
			return this.$store.state.currentView
		},

		showNav() {
			return this.$store.state.showNav
		}
	},
	methods: {
		currentView(view) {
			this.$store.commit('setCurrentView', view)
			this.$store.commit('toggleNav', false)
		},

		logout() {
			this.$router.push('/login')
		}
	}
}
</script>

<style scoped>
	nav {
		width: 20%;
		max-width: 250px;
		min-height: calc(100vh - 52px);
		border-right: 1px solid rgba(0, 0, 0, 0.15);
		padding: 4rem 0;
		position: fixed;
		top: 52px;
		left: 0;	
		background: #fff;
	}

	ul {
		padding: 0;
		list-style: none;
	}

	ul li {
		padding: 0.5rem 0;
	}

	ul li h1 {
		margin-left: 1rem;
		margin-bottom: 3rem;
		font-size: 2rem;
	}

	ul li a {
		display: block;
		padding-left: 1rem;
	}

	ul li a:hover {
		text-decoration: none;
	}

	@media screen and (min-width: 992px) {
		.logout {
			display: none;
		}
	}

	@media screen and (max-width: 992px) {
		nav {
			max-width: 40%;
			width: 40%;
			background: #fff;
			z-index: 3;
			transition: all 0.2s ease-in-out;
			transform: translateX(-100%);
		}

		nav.show {
			transform: translateX(0)
		}
	}

	@media screen and (max-width: 520px) {
		nav {
			width: 75%;
			max-width: 75%;
		}
	}
</style>