<template>
	<main class="view">
		<topbar></topbar>
		<div class="content">
			<navigation></navigation>
			<div class="view-container">
				<b-container>
					<b-row>
						<b-col sm="12" class="no-padding">
							<h1 class="view-title">Duración de llamadas por rangos de tiempo</h1>
						</b-col>
						<b-col sm="12" class="card table">
							<b-table-lite :items="items" responsive></b-table-lite>
						</b-col>
						<b-col sm="12" class="card">
							<bar-chart :data="chartData" :colors="['#e72']"></bar-chart>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</div>
	</main>
</template>

<script>
import topbar from '../components/topbar.vue'
import navigation from '../components/navigation.vue'
export default {
	components: { topbar, navigation },
	data() {
		return {
			items: [
				{ rango: '0 y 1', cantidad: '40' },
				{ rango: '2 y 4', cantidad: '182' },
				{ rango: '5 y 10', cantidad: '3.231' },
				{ rango: '11 y 30', cantidad: '2.896' },
				{ rango: '31 y 60', cantidad: '4.935' },
				{ rango: '61 y 180', cantidad: '1.523' },
				{ rango: '181 y más', cantidad: '2' },
			],

			chartData: [
				['0 y 1.', 40], 
				['2 y 4', 182], 
				['5 y 10', 3231],
				['11 y 30', 2896],
				['31 y 60', 4935],
				['61 y 180', 1523],
				['181 y más', 2],
			]
		}

	}
}
</script>
