<template>
	<main class="view">
		<topbar></topbar>
		<div class="content">
			<navigation></navigation>
			<div class="view-container">
				<b-container>
					<b-row>
						<b-col sm="12" class="no-padding">
							<h1 class="view-title">Duración de llamadas por compañía</h1>
						</b-col>
						<b-col sm="12" class="card table">
							<b-table-lite :items="items" responsive></b-table-lite>
						</b-col>
						<b-col sm="12" class="card">
							<bar-chart :data="chartData" :colors="['#e72']"></bar-chart>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</div>
	</main>
</template>

<script>
import topbar from '../components/topbar.vue'
import navigation from '../components/navigation.vue'
export default {
	components: { topbar, navigation },
	data() {
		return {
			items: [
				{ destino: 'CLARO CHILE S.A.', duracion: '50.43' },
				{ destino: 'ENTEL PCS TELECOMUNICACIONES S.A', duracion: '121.32' }, 
				{ destino: 'GTD - Telesur', duracion: '0.01' }, 
				{ destino: 'Local - Movil - VoIN', duracion: '0.10' }, 
				{ destino: 'Movistar', duracion: '109.72' }, 
				{ destino: 'SIMPLE SPA', duracion: '0.09' }, 
				{ destino: 'Virgin - TRIBE MOBILE SPA', duracion: '2.68' }, 
				{ destino: 'VTR MOVIL S.A.', duracion: '4.46' }, 
				{ destino: 'WOM - NEXTEL S.A.', duracion: '129.64' }, 
			],

			chartData: [
				['CLARO CHILE S.A.', 50.43], 
				['ENTEL PCS TELECOMUNICACIONES S.A', 121.32], 
				['GTD - Telesur', 0.01],
				['Local - Movil - VoIN', 0.10],
				['Movistar', 109.72],
				['SIMPLE SPA', 0.09],
				['Virgin - TRIBE MOBILE SPA', 2.68],
				['VTR MOVIL S.A.', 4.46],
				['WOM - NEXTEL S.A.', 29.64],
			]
		}

	}
}
</script>
