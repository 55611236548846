<template>
	<main class="login-view">
		<div class="card">
			<img src="../img/altera_logo.png" alt="Altera">
			<div class="form-container">
				<div class="input-container">
					<label>Usuario</label>
					<input type="text" class="primary-border">
				</div>
				<div class="input-container">
					<label>Contraseña</label>
					<input type="password" class="primary-border">
				</div>
				<button @click="login" class="btn primary-bg">Ingresar</button>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	methods: {
		login() {
			this.$router.push('/destinos')
		}
	}
}
</script>

<style scoped>
	.login-view {
		width: 100%;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	.card {
		width: 100%;
		max-width: 500px;
		text-align: center;
		padding: 3rem;
		margin-top: 10%;
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	}

	img {
		max-width: 150px;
		margin: auto;
	}

	.form-container {
		padding: 4.5rem 1.5rem;
	}

	.input-container {
		display: flex;
		flex-direction: column;
		text-align: left;
		margin-bottom: 2rem;
	}

	label {
		font-weight: 500;
		margin-bottom: 0;
	}

	input {
		border: none;
		border-bottom: 2px solid #ccc;
	}

	.btn {
		font-weight: 500;
		text-transform: uppercase;
		border-radius: 0;
		margin-top: 1.5rem;
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		transition: all 0.1s ease-in-out;
	}

	.btn:hover {
		color: #fff;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	}

	input:focus,
	input:active {
		outline: none;
		box-shadow: none;
	}
</style>