<template>
	<header class="primary-bg">
		<div class="left-side">
			<div class="toggle" @click="toggleNav">
				<span></span>
				<span></span>
				<span></span>
			</div>
			<img src="../img/logo_altera_top_blanco.png" alt="Altera">
		</div>
		<a href="#" class="logout" @click.prevent="logout">Salir</a>
	</header>
</template>

<script>
export default {
	data() {
		return {
			showNav: false
		}
	},
	methods: {
		toggleNav() {
			this.showNav = !this.showNav
			this.$store.commit('toggleNav', this.showNav)
		},

		logout() {
			this.$router.push('/login')
		}
	}
}
</script>

<style scoped>
	header {
		padding: 0.75rem 2rem 1rem 2rem;
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		width: 100%;
		position: fixed;
		top: 0;
		z-index: 5;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.left-side {
		display: flex;
	}

	img {
		max-width: 80px;
	}

	.toggle {
		margin-right: 1rem;
		cursor: pointer;
		padding: 8px 5px 5px;
	}

	.toggle span {
		display: block;
		width: 20px;
		height: 3px;
		background: #fff;
		margin-bottom: 3px;
	}

	.toggle span:first-child {
		margin: 3px 0;
	}

	.toggle span:last-child {
		margin-bottom: 0;
	}

	.logout {
		font-weight: 500;
		color: #fff;
	}

	.logout:hover {
		text-decoration: none;
	}

	@media screen and (min-width: 993px) {
		.toggle {
			display: none;
		}
	}

	@media screen and (max-width: 992px) {
		.logout {
			display: none;
		}
	}

	@media screen and (max-width: 600px) {
		header {
			padding: 0.75rem 1rem 1rem;
		}
	}
</style>