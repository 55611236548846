import login from './views/login.vue'
import destinos from './views/destinos.vue'
import duracion from './views/duracionComp.vue'
import rangos from './views/duracionRango.vue'
import codigos from './views/codigos.vue'

const routes = [
	{
		path: '*',
		redirect: '/login'
	},
	{
		path: '/destinos',
		component: destinos
	},
	{
		path: '/duracion',
		component: duracion
	},
	{
		path: '/rangos',
		component: rangos
	},
	{
		path: '/login',
		component: login
	},
	{
		path: '/codigos',
		component: codigos
	}
]

module.exports = routes