<template>
	<main class="view">
		<topbar></topbar>
		<div class="content">
			<navigation></navigation>
			<div class="view-container">
				<b-container>
					<b-row>
						<b-col sm="12" class="no-padding">
							<h1 class="view-title">Códigos de retorno de llamadas</h1>
						</b-col>
						<b-col sm="12" class="card table">
							<b-table-lite :items="items" responsive></b-table-lite>
						</b-col>
						<b-col sm="12" class="card">
							<bar-chart :data="chartData" :colors="['#e72']"></bar-chart>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</div>
	</main>
</template>

<script>
import topbar from '../components/topbar.vue'
import navigation from '../components/navigation.vue'
export default {
	components: { topbar, navigation },
	data() {
		return {
			items: [
				{ codigo: '410', descripcion: 'No encontrado/Expiración de Solicitud', llamadas: '430' },
				{ codigo: '480', descripcion: 'Usuario no contesta', llamadas: '15.000' },
				{ codigo: '484', descripcion: 'Dirección incompleta', llamadas: '2.400' },
				{ codigo: '486', descripcion: 'Número ocupado', llamadas: '7.809' },
				{ codigo: '487', descripcion: 'El destino cancela la llamada', llamadas: '4.500' },
			],

			chartData: [
				['No encontrado/Expiración de Solicitud', 430],
				['Usuario no contesta', 15000], 
				['Dirección incompleta', 2400], 
				['Número ocupado', 7809], 
				['El destino cancela la llamada', 4500], 
			]
		}

	},
}
</script>
