import Vue from 'vue/dist/vue.min.js'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './css/styles.scss'

Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(Chartkick.use(Chart))

import routes from './router.js'
const router = new VueRouter({routes, mode: 'history'})
router.afterEach( (to, from)=> {
	window.scrollTo(0, 0)
})

import storeModule from './store.js'
const store = new Vuex.Store(storeModule)
 
const vm = new Vue({
	el: '#app',
	router,
	store
})