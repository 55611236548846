<template>
	<main class="view">
		<topbar></topbar>
		<div class="content">
			<navigation></navigation>
			<div class="view-container">
				<b-container>
					<b-row>
						<b-col sm="12" class="no-padding">
							<h1 class="view-title">Cantidad de llamadas por destino</h1>
						</b-col>
						<b-col sm="12" class="card table">
							<b-table-lite :items="items" responsive></b-table-lite>
						</b-col>
						<b-col sm="12" class="card">
							<bar-chart :data="chartData" :colors="['#e72']"></bar-chart>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</div>
	</main>
</template>

<script>
import topbar from '../components/topbar.vue'
import navigation from '../components/navigation.vue'
export default {
	components: { topbar, navigation },
	data() {
		return {
			items: [
				{ destino: 'CLARO CHILE S.A.', cantidad: '2.124' },
				{ destino: 'ENTEL PCS TELECOMUNICACIONES S.A', cantidad: '4.969' }, 
				{ destino: 'GTD - Telesur', cantidad: '1' }, 
				{ destino: 'Local - Movil - VoIN', cantidad: '3' }, 
				{ destino: 'Movistar', cantidad: '4.116' }, 
				{ destino: 'SIMPLE SPA', cantidad: '3' }, 
				{ destino: 'Virgin - TRIBE MOBILE SPA', cantidad: '132' }, 
				{ destino: 'VTR MOVIL S.A.', cantidad: '176' }, 
				{ destino: 'WOM - NEXTEL S.A.', cantidad: '1.285' }, 
			],

			chartData: [
				['CLARO CHILE S.A.', 2124], 
				['ENTEL PCS TELECOMUNICACIONES S.A', 4969], 
				['GTD - Telesur', 1],
				['Local - Movil - VoIN', 3],
				['Movistar', 4116],
				['SIMPLE SPA', 3],
				['Virgin - TRIBE MOBILE SPA', 132],
				['VTR MOVIL S.A.', 176],
				['WOM - NEXTEL S.A.', 1285],
			]
		}

	},
}
</script>
